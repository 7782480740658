import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from '../components/Layout/Layout';
import Header from '../components/Header/Header-2';
import SEO from "../components/seo";

const getdata = graphql`
{
  wpgraphql {
    page(id: "cG9zdDo3MjYz") {
      uri
      seo {
        canonical
        metaDesc
        metaKeywords
        focuskw
        title
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphTitle
        opengraphType
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
}
`
const About = () => {
  const data = useStaticQuery(getdata);
  const uri = data.wpgraphql.page.uri
  const desc = data.wpgraphql.page.seo.metaDesc
  const seo = data.wpgraphql.page.seo
  const common = data.wpgraphql.page.about
  return (
    <>
      <Layout>
        <SEO title="About" description={desc} canonical={uri} seo={seo} />
        <Header home={false} menu='about' />
        <main>
          <section class="contact-title py-5">
            <div class="container">
              <h2 class="page-title text-white">About PowerPatent</h2>
            </div>
          </section>
          <section class="py-5">
            <div class="container">
              <div class="row">
                {/* <div className="card-text" dangerouslySetInnerHTML={{ __html: common.content }} /> */}
                <div className="card-text">
                  <section class="mainSpacing">
                    <div class="container">
                      <div class="row">
                        <div class="col-md-12 align-self-center mb-4">
                          <h3>About PowerPatent</h3>
                          <p>We are a team of experienced Silicon Valley patent professionals and AI technologists who are dedicated to developing patent drafting and prosecution software services that meet the highest standards of quality and accuracy.</p>
                          <p>Our mission is to empower our clients to navigate the patent process with ease and confidence. We understand that the patent system can be complex and daunting, and that’s why we are committed to providing personalized guidance and support every step of the way.</p>
                          <p>At PowerPatent, we believe in the importance of innovation and creativity. We are passionate about helping our clients turn ideas into tangible intellectual property, and we take pride in being a part of their journey towards success.</p>
                          <p>Contact us today to learn more about our software services and how we can help you achieve your goals.</p>
                          <p>&nbsp;</p>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    </>
  )
}
export default About